<template>
  <div>
    <loader v-if="vseZBS"></loader>
    <div v-if="!vseZBS">
      <p class="description">
        Для оформления займа нам нужно определить ваше местонахождение. Данные о вашем местонахождении никогда не
        будут переданы третьим лицам, и будут использованы только в процессе оформления договора.
      </p>
      <p>У вас установлен запрет на определение местоположения.</p>
      <p>Измените это в настройках устройства и повторите попытку</p>
      <ul>
        <li>
          <a href="https://yandex.ru/support/common/browsers-settings/geolocation.html">Открыть инструкцию</a>
        </li>
        <li>
          <a href="https://support.apple.com/ru-ru/HT207092">Открыть инструкцию для iOS</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: "forRedirectIos",
    data(){
      return {
        vseZBS:true,
        geoErrorCode: -1,
        sendResult: {
          latitude: 0,
          longitude: 0,
          altitude: 0,
          accuracy: 0,
          altitudeAccuracy: 0,
          heading: 0,
          speed: 0,
        },
        demand:0,
      }
    },
    components: {
      loader: () => import('@/components/other/loader'),
    },
    methods:{
      sendGeo() {
        let url = 'https://mapp.msk.finmoll.com/cgi/vsl.exe?';
        let date = new Date();
        let month = (date.getMonth() + 1) < 10 ? '0' + (date.getMonth() + 1) : (date.getMonth() + 1);
        let day = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
        let h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
        let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
        let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
        let outDateStr = date.getFullYear()+''+month+''+day+' '+h+':'+m+':'+s;

        url += 'dm=' +  encodeURIComponent(this.demand);
        url += '&lat=' + encodeURIComponent(this.sendResult.latitude);
        url += '&lon=' + encodeURIComponent(this.sendResult.longitude);
        url += '&dt=' + encodeURIComponent(outDateStr);
        url += '&s=2';

          this.$myApi.singleRequest({
            action:'redirectAppGeoCollect',
            url: url,
          }).then(res=>{
            console.log(res)
          }).catch(res=>{
            console.log(res)
          }).finally(()=>{

          });
      },
      geoSuccessWatch(pos) {
        this.vseZBS = true;
        this.sendResult.latitude = pos.coords.latitude != null ? pos.coords.latitude : 0;
        this.sendResult.longitude = pos.coords.longitude != null ? pos.coords.longitude : 0;
        this.sendResult.altitude = pos.coords.altitude != null ? pos.coords.altitude : 0;
        this.sendResult.accuracy = pos.coords.accuracy != null ? pos.coords.accuracy : 0;
        this.sendResult.altitudeAccuracy = pos.coords.altitudeAccuracy != null ? pos.coords.altitudeAccuracy : 0;
        this.sendResult.heading = pos.coords.heading != null ? pos.coords.heading : 0;
        this.sendResult.speed = pos.coords.speed != null ? pos.coords.speed : 0;
        this.geoErrorCode = '';
        this.sendGeo();
      },
      geoError(err) {
        if (err.code != 3) {
          this.vseZBS = false;
          this.geoErrorCode = err.code;
        }
      },
      timerRedirect(){
        let self = this;
        setTimeout(()=>{
          if (self.vseZBS) {
            self.redirectToApp();
          } else {
            self.timerRedirect();
          }
        }, 5000);
      },
      redirectToApp(){
        // document.location.href= 'https://apps.apple.com/ru/app/trueconf/id536475636';
        document.location.href= 'https://truec.msk.finmoll.com/c/'+this.demand;
      }
    },
    watch: {
      'sendResult.longitude'(val) {
        this.sendGeo();
      },
      'sendResult.latitude'(val) {
        this.sendGeo();
      },
    },
    mounted() {
      this.isDeviceNavigatorIsset = (navigator.geolocation) ? true : false;
      navigator.geolocation.watchPosition(this.geoSuccessWatch, this.geoError, this.geoOption);
      let params = this.$route.query;
      if (params['demand'] != undefined) {
        this.demand = params['demand'];
      }
      this.timerRedirect();
    }
  }
</script>

<style scoped>

</style>
